<template>
  <router-link
      :to="`${ getUser?.id === data?.id ? '/profile' : getUser?.id ? '/user/' + data?.id : '' }`"
      class="activist-rating-card card"
  >
    <div class="card__left" @click="$emit('visibleAlert')">
      <img class="card__left-avatar" :src="getUserAvatar(data.avatarSrc.fileSrc)" alt="">
      <div class="card__left-name">
        {{ data.login }}
        <UiRating size="14" v-model="data.starRating" />
        <div class="user-info mob">
          <div v-if="data.district" class="user-info__city">
            <img src="@/assets/svg/icon-location.svg" alt="">
            {{ data.region.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="card__center" @click="$emit('visibleAlert')">
      <div class="card__center-rating">
        <p>{{ $t($store.state.userRating[data.starRating]) }}</p>
        <UiRating size="25" v-model="data.starRating" />
      </div>
      <div v-if="data.district" class="card__center-city">
        <img src="@/assets/svg/icon-location.svg" alt="">
        {{ data?.city || data.region?.name }}
      </div>
      <p class="card__center-requests">{{ $t('length-requests-closed-total', {from: data.totalOrdersCount - data.acceptedOrdersCount, to: data.totalOrdersCount}) }}</p>
    </div>
    <div class="card__right" @click="$emit('visibleAlert')">
      <span>{{ data.score }}</span>
      <p class="card__right-req">{{ $t('length-requests-closed-total', {from: data.totalOrdersCount - data.acceptedOrdersCount, to: data.totalOrdersCount}) }}</p>
    </div>
  </router-link>
</template>

<script>
import { getUserAvatar } from "@/utils/user";
import {mapGetters} from "vuex";
export default {
  name: "ActivistRatingCard",
  components: {
    UiRating: () => import('@/components/ui/UiRating')
  },

  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      getUserAvatar,
    }
  },

  computed: {
    ...mapGetters(['getUser'])
  }
}
</script>

<style lang="scss" scoped>
.card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
  border-radius: 200px;
  padding: 12px 20px 11px 20px;

  @media (max-width: 992px) {
    padding: 17px 20px 18px 10px;
  }

  &__left {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    max-width: 200px;

    @media (max-width: 992px) {
      max-width: 200px;
      gap: 10px;
    }

    &-avatar {
      width: 80px;
      height: 80px;
      border: 1px solid #CE2121;
      border-radius: 114.583px;
      object-fit: cover;

      @media (max-width: 992px) {
        width: 50px;
        height: 50px;
      }
    }
    &-name {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #343432;

      @media (max-width: 992px) {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }

      ::v-deep .ui-rating {
        display: none;

        @media (max-width: 992px) {
          display: inline;
        }
      }

      .user-info {
        display: flex;
        align-items: center;
        gap: 10px;

        @media (min-width: 992px) {
          display: none;
        }

        &__city {
          display: flex;
          align-items: center;
          gap: 5px;
          max-width: 211px;
          width: 100%;
          font-family: "Gotham Pro Regular";
          font-weight: 400;
          font-size: 10px;
          line-height: 10px;
          color: #343432;

          img {
            width: 14px;
            height: 16px;
          }
        }

        &__req {
          font-family: "Gotham Pro Regular";
          font-weight: 400;
          font-size: 10px;
          line-height: 10px;
          color: #9A9A9A;
          white-space: nowrap;
        }
      }
    }
  }

  &__center {
    display: flex;
    align-items: center;
    justify-content: inherit;
    gap: 5px;
    width: 100%;
    margin-right: 30px;

    @media (max-width: 1200px) {
      gap: 25px;
    }

    @media (max-width: 992px) {
      display: none;
    }

    &-rating {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 200px;
      width: 100%;

      p {
        font-family: "Gotham Pro Regular";
        font-weight: 400;
        font-size: 14px;
        line-height: 120.2%;
        color: #9A9A9A;
      }
    }
    &-city {
      display: flex;
      align-items: center;
      gap: 5px;
      max-width: 211px;
      width: 100%;
      font-family: "Gotham Pro Regular";
      font-weight: 400;
      font-size: 16px;
      line-height: 120.2%;
      color: #343432;
    }
    &-requests {
      max-width: 144px;
      width: 100%;
      font-family: "Gotham Pro Regular";
      font-weight: 400;
      font-size: 16px;
      line-height: 120.2%;
      color: #9A9A9A;
      max-width: 140px;
      text-align: center;
      width: 100%;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 992px) {
      flex-direction: column;
      gap: 8px;
    }

    .ui-rating {
      @media (min-width: 992px) {
        display: none;
      }
    }

    &-req {
      font-family: "Gotham Pro Regular";
      font-weight: 400;
      font-size: 10px;
      line-height: 10px;
      color: #9A9A9A;
      display: none;

      @media (max-width: 992px) {
        display: inline;
        text-align: end;
      }
    }

    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 15px;
      color: #FFFFFF;
      padding: 12px 18px;
      width: max-content;
      background: #E21F1F;
      box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
      border-radius: 200px;
      align-self: end;

      @media (max-width: 992px) {
        padding: 10px 16px;
      }
    }
  }
}
</style>
